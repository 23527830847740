import { AxiosResponse } from 'axios';
import API from '../Common/axios';
import { ListParams } from '../Common/types';
import { RegionInterface } from './types';

export const getRegions = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/regions', { params });
};

export const getRegionsMinimalList = (params?: {
  is_active?: boolean;
  filter_operational_manager?: string;
}): Promise<AxiosResponse> => {
  return API.get(`v1/regions/minimal_list`, { params });
};

export const getRegion = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/regions/${id}`);
};

export const createRegion = (region: {
  name: string;
  operational_manager_id?: string;
  regional_manager_id?: string;
  residence_ids: number[];
}): Promise<AxiosResponse> => {
  return API.post(`v1/regions`, { region });
};

export const updateRegion = (
  id: number | undefined,
  region: { name?: string; regional_manager_id?: string; operational_manager_id?: string; residence_ids?: number[] }
): Promise<AxiosResponse> => {
  return API.patch(`v1/regions/${id}`, { region });
};

export const deleteRegion = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/regions/${id}`);
};
