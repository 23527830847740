import { ChevronLeft } from '@mui/icons-material';
import ExtraHoursIcon from '@mui/icons-material/AccessTime';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import SentInvoicesIcon from '@mui/icons-material/AirplanemodeActive';
import SalariesIcon from '@mui/icons-material/Savings';
import ResidenceGroupsIcon from '@mui/icons-material/Assignment';
import SavedInvoicesIcon from '@mui/icons-material/AttachMoney';
import ResidencesIcon from '@mui/icons-material/Business';
import ScheduleIcon from '@mui/icons-material/CalendarViewWeek';
import InterviewsIcon from '@mui/icons-material/ChatBubbleOutline';
import InternalRecruitmentIcon from '@mui/icons-material/DoubleArrow';
import FutureIcon from '@mui/icons-material/Face';
import EmployeeAgreementsIcon from '@mui/icons-material/FilterNone';
import UsersIcon from '@mui/icons-material/Group';
import ResidenceClustersIcon from '@mui/icons-material/GroupWorkOutlined';
import FaqIcon from '@mui/icons-material/Help';
import AgreementsIcon from '@mui/icons-material/HowToVote';
import MenuIcon from '@mui/icons-material/Menu';
import NewsIcon from '@mui/icons-material/Newspaper';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import WorkShiftsIcon from '@mui/icons-material/PushPin';
import SalaryClassesIcon from '@mui/icons-material/SavingsOutlined';
import EducationIcon from '@mui/icons-material/School';
import RegionsIcon from '@mui/icons-material/SouthAmerica';
import DashboardIcon from '@mui/icons-material/SpaceDashboardOutlined';
import MissedWorktimesIcon from '@mui/icons-material/Today';
import ManagementIcon from '@mui/icons-material/TrackChanges';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, CSSObject, IconButton, List, Drawer as MuiDrawer, Theme, styled } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import Dicon from '../Icons/Ikon-D-Vit.png';
import TLicon from '../Icons/Ikon-TL-Vit.png';
import { commonStyles, css } from '../styling';
import { AuthContextValue, NotificationContextValue } from '../types';
import DrawerMenuItem from './DrawerMenuItem';
import MenuCollapseControl from './MenuCollapseControl';
import { twMerge } from 'tailwind-merge';
import ReportBugs from './ReportBugs';
import ConditionalParent from '../ConditionalParent';
import logo from '../../Common/logo.png';
import NotificationContext from '../NotificationContext';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const drawerWidth = 250;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MENU_EXPAND_STATE_LSKEY = 'menuExpandState';

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
};

export default function DrawerMenu({ open, setOpen }: Props) {
  const navigate = useNavigate();
  const { profile, logout, hasPermissions } = useContext(AuthContext) as AuthContextValue;
  const [expandedAreas, setExpandedAreas] = useState<boolean[]>([false, false, false, false, false]);
  const { jobOpeningsCount } = useContext(NotificationContext) as NotificationContextValue;

  useEffect(() => {
    const stored = localStorage.getItem(MENU_EXPAND_STATE_LSKEY);
    if (stored) setExpandedAreas(JSON.parse(stored));
  }, []);

  function handleExpandClick(expanded: boolean[]) {
    setExpandedAreas(expanded);
    localStorage.setItem(MENU_EXPAND_STATE_LSKEY, JSON.stringify(expanded));
  }

  return (
    <Drawer variant="permanent" open={open}>
      <div className={twMerge('flex items-center justify-end px-2 py-8 my-0.5 transition-all', !open && 'px-3')}>
        <ConditionalParent
          on={process.env.REACT_APP_FEATURE_DASHBOARD === 'true'}
          parent={(children) => <Link to="/dashboard">{children}</Link>}
        >
          <div className={twMerge('px-4 transition-transform ease-[cubic-bezier(1,0,.9,.4)]', !open && 'scale-0')}>
            <img src={logo} alt="Logo" width="160px" />
          </div>
        </ConditionalParent>

        <IconButton onClick={() => setOpen(!open)}>{open ? <ChevronLeft /> : <MenuIcon />}</IconButton>
      </div>

      <List disablePadding>
        <MenuCollapseControl
          label="Verksamhet"
          expandKey={0}
          expanded={expandedAreas}
          onTrigger={handleExpandClick}
          open={open}
        >
          {process.env.REACT_APP_FEATURE_DASHBOARD === 'true' && (
            <DrawerMenuItem icon={<DashboardIcon />} label="Startsida" open={open} link="/dashboard" />
          )}
          <DrawerMenuItem icon={<VisibilityIcon />} label="Översikt" open={open} link="/teams" />
          <DrawerMenuItem icon={<ScheduleIcon />} label="Schema" open={open} link="/schedule" />
          <DrawerMenuItem icon={<WorkShiftsIcon />} label="Pass" open={open} link="/work-shifts" />
          <DrawerMenuItem icon={<UsersIcon />} label="Användare" open={open} link="/users" />
          <DrawerMenuItem icon={<NewsIcon />} label="Nyheter" open={open} link="/news" />
          {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
            <>
              <DrawerMenuItem icon={<ExtraHoursIcon />} label="Extra arbetstimmar" open={open} link="/extra-hours" />
              <DrawerMenuItem
                icon={<NotificationImportantOutlinedIcon />}
                label="Katastrofpass"
                open={open}
                link="/katastrofpass"
              />
            </>
          )}
        </MenuCollapseControl>

        <MenuCollapseControl
          label="Rekrytering"
          expandKey={1}
          expanded={expandedAreas}
          onTrigger={handleExpandClick}
          open={open}
        >
          <DrawerMenuItem
            icon={<InternalRecruitmentIcon className="!-rotate-90" />}
            label="Internrekrytering"
            open={open}
            link="/job_openings"
            badge={jobOpeningsCount}
          />
          <DrawerMenuItem
            icon={
              <div className="-m-2">
                <img src={TLicon} alt="TLicon" width={40} height={40} />
              </div>
            }
            label="Teamledare"
            open={open}
            link="/job_applications/team_leader"
          />
          <DrawerMenuItem
            icon={
              <div className="-m-1.5">
                <img src={Dicon} alt="Dicon" width={36} height={36} />
              </div>
            }
            label="Deltagare"
            open={open}
            link="/job_applications/participant"
          />
          <DrawerMenuItem icon={<InterviewsIcon />} label="Intervjuer" open={open} link="/interviews" />
        </MenuCollapseControl>

        <MenuCollapseControl
          label="Hantering"
          expandKey={2}
          expanded={expandedAreas}
          onTrigger={handleExpandClick}
          open={open}
          condition={hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
        >
          {hasPermissions(['admin', 'operational_leader']) && (
            <DrawerMenuItem icon={<ResidenceClustersIcon />} label="Grupper" open={open} link="/residence-clusters" />
          )}
          {hasPermissions(['admin', 'operational_leader']) && (
            <DrawerMenuItem icon={<ResidenceGroupsIcon />} label="Avtalsparter" open={open} link="/residence-groups" />
          )}
          <DrawerMenuItem icon={<RegionsIcon />} label="Regioner" open={open} link="/regions" />
          {hasPermissions(['admin', 'operational_leader']) && (
            <DrawerMenuItem icon={<ResidencesIcon />} label="Boenden" open={open} link="/residences" />
          )}
          {hasPermissions(['admin']) && (
            <DrawerMenuItem icon={<SalaryClassesIcon />} label="Lönearter" open={open} link="/salary-classes" />
          )}
        </MenuCollapseControl>

        <MenuCollapseControl
          label="Ekonomi"
          expandKey={3}
          expanded={expandedAreas}
          onTrigger={handleExpandClick}
          open={open}
          condition={hasPermissions(['admin'])}
        >
          <DrawerMenuItem icon={<AgreementsIcon />} label="Avtal" open={open} link="/agreements" />
          <DrawerMenuItem
            icon={<MissedWorktimesIcon />}
            label="Kontroll arbetspass"
            open={open}
            link="/missed-worktimes"
          />
          <DrawerMenuItem icon={<ManagementIcon />} label="Fakturarader" open={open} link="/management" />
          <DrawerMenuItem icon={<SavedInvoicesIcon />} label="Skapade fakturor" open={open} link="/saved-invoices" />
          <DrawerMenuItem icon={<SentInvoicesIcon />} label="Skickade fakturor" open={open} link="/sent-invoices" />
          <DrawerMenuItem icon={<SalariesIcon />} label="Lönehantering" open={open} link="/export" />
        </MenuCollapseControl>

        <MenuCollapseControl
          label="Mobil admin"
          expandKey={4}
          expanded={expandedAreas}
          onTrigger={handleExpandClick}
          open={open}
          condition={hasPermissions(['admin', 'operational_leader'])}
        >
          <DrawerMenuItem icon={<FaqIcon />} label="FAQ" open={open} link="/faq" />
          <DrawerMenuItem icon={<FutureIcon />} label="Din framtid" open={open} link="/future" />
          <DrawerMenuItem icon={<EmployeeAgreementsIcon />} label="Mallar" open={open} link="/employee-agreements" />
          <DrawerMenuItem icon={<EducationIcon />} label="Utbildningar" open={open} link="/education" />
        </MenuCollapseControl>
      </List>

      <div className={twMerge('pb-5 pt-12', open ? 'px-5' : 'pt-8 px-3')}>
        <div className={twMerge('flex items-center justify-between', !open && 'flex-col items-start gap-4')}>
          <Link to={`/profile/${profile?.id}`}>
            <Avatar className="cursor-pointer">{profile?.first_name.charAt(0)}</Avatar>
          </Link>

          <IconButton
            onClick={(): void => {
              logout();
              navigate('login');
            }}
          >
            <PowerSettingsNewIcon />
          </IconButton>
        </div>

        {open && (
          <>
            <h3
              className={twMerge(css(commonStyles.hover), 'mb-2.5 mt-4 text-lg leading-none font-bold')}
              onClick={(): void => navigate(`/profile/${profile?.id}`)}
            >
              {profile.first_name + ' ' + profile?.surname}
            </h3>
            {hasPermissions(['admin']) && <span>{profile.role.name}</span>}
            <ReportBugs />
          </>
        )}
      </div>
    </Drawer>
  );
}
