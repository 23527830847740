import React, { MouseEvent, useState, useEffect, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotify } from '../Common/snackbarHooks';
import { RotateLoader } from 'react-spinners';
import { encode } from 'base-64';

import AuthContext from '../Common/AuthContext';

import { commonStyles, css } from '../Common/styling';
import { MenuItem, Tooltip } from '@mui/material';
import TableBox, { TableBoxColumn, TableBoxExtraHeader } from '../Common/Table/TableBox';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTipOutlined';
import GroupIcon from '@mui/icons-material/Group';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { stringToPositiveInt, useQuery } from '../Common/utilities';
import { TeamCatastropheStatisticsRow } from './types';
import { getTeamCatastropheStatistics } from './statisticsApi';
import { getRegionsMinimalList } from '../Region/regionApi';
import { getUsersMinimalList } from '../User/usersApi';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';
import { AuthContextValue, OptionsInterface } from '../Common/types';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import FilterDatePicker from '../Common/FilterPopover/FilterDatePicker';
import CatastropheWorkShiftsFilterPopover from './components/CatastropheWorkShiftsFilterPopover';
import { MenuTableCell } from '../Common/Table';
import QuickLinkAction from '../Common/ButtonLinks/QuickLinkAction';
import { filterSortDefaults as userFilterDefaults } from '../User/usersSchema';

const tableExtraHeaders: TableBoxExtraHeader[] = [
  { colSpan: 1 },
  { label: 'Ej godkända katastrofpass senaste:', colSpan: 3 },
  { label: 'Godkända katastrofpass senaste:', colSpan: 3 },
  { colSpan: 3 },
];

const tableColumns: TableBoxColumn[] = [
  { id: 'name', label: 'Team', width: 'md', lineStyle: 'compress' },
  { id: 'kp_12', label: '12 mån', width: 'xs' },
  { id: 'kp_6', label: '6 mån', width: 'xs' },
  { id: 'kp_2', label: '2 mån', width: 'xs' },
  { id: 'approved_kp_12', label: '12 mån', width: 'xs' },
  { id: 'approved_kp_6', label: '6 mån', width: 'xs' },
  { id: 'approved_kp_2', label: '2 mån', width: 'xs' },
  { id: 'regional_manager_name', label: 'Regionchef (RC)', width: 'md', lineStyle: 'compress' },
  { id: 'operational_manager_name', label: 'Verksamhetsansvarig (VA)', width: 'md', lineStyle: 'compress' },
];

const PAGE_SIZE = 50;

const CatastropheWorkShifts: React.FC = () => {
  const pageTopRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyError } = useNotify();

  const [teams, setTeams] = useState<Array<TeamCatastropheStatisticsRow>>();
  const [totalTeams, setTotalTeams] = useState<number>();
  const [loading, setLoading] = useState(true);

  const [regions, setRegions] = useState<Array<OptionsInterface>>([]);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [users, setUsers] = useState<Array<OptionsInterface>>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [residenceGroups, setResidenceGroups] = useState<Array<OptionsInterface>>([]);
  const [loadingResidenceGroups, setLoadingResidenceGroups] = useState(true);

  const [firstLoad, setFirstLoad] = useState(true);
  const { hasPermissions, profile } = useContext(AuthContext) as AuthContextValue;

  const [query, setQuery] = useQuery(
    {
      regions: (val) => val,
      residenceGroups: (val) => val,
      operationalManager: (val) => val,
      date: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      showInactiveTeams: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showWithoutCatastrophe: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      showDifficultTeams: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      sortBy: (val) => val,
      sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
      page: (val) => stringToPositiveInt(val),
    },
    { backConverter: { date: (val) => val?.format('YYYY-MM-DD') } }
  );

  // This useEffect takes place on first page load
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchTeamCatastropheStats();
  }, [
    query.residenceGroups,
    query.regions,
    query.date,
    query.showDifficultTeams,
    query.showWithoutCatastrophe,
    query.showInactiveTeams,
    query.sortBy,
    query.sortOrder,
    query.page,
  ]);

  useEffect(() => {
    fetchResidenceGroups({ is_active: true, filter_operational_manager: query.operationalManager });
    fetchRegions({ is_active: true, filter_operational_manager: query.operationalManager });

    if (firstLoad) {
      setFirstLoad(false);
    } else {
      // Clear residence groups and regions when choosing a new operational manager
      if (query.operationalManager && (query.residenceGroups || query.regions)) {
        setQuery({ residenceGroups: undefined, regions: undefined });
      } else {
        fetchTeamCatastropheStats();
      }
    }
  }, [query.operationalManager]);

  const fetchTeamCatastropheStats = (): void => {
    setLoading(true);
    getTeamCatastropheStatistics({
      filter_operational_manager: hasPermissions(['operational_manager']) ? profile.id : query.operationalManager,
      filter_residence_groups: query.residenceGroups?.split(',').map((id) => stringToPositiveInt(id)),
      filter_regions: query.regions?.split(',').map((id) => stringToPositiveInt(id)),
      date: query.date?.format('YYYY-MM-DD'),
      show_difficult: query.showDifficultTeams ?? true,
      show_without_catastrophe: query.showWithoutCatastrophe ?? false,
      show_inactive: query.showInactiveTeams ?? false,
      sort_by: query.sortBy ?? 'kp_12',
      sort_order: query.sortOrder ?? 'desc',
      page: query.page ?? 1,
    })
      .then(({ data }) => {
        setTeams(data.data);
        setTotalTeams(data.meta.count);
        pageTopRef.current?.scrollIntoView();
      })
      .catch((err) => {
        notifyError('Det gick inte att hämta listan över katastrofpass');
      })
      .finally(() => setLoading(false));
  };

  const fetchUsers = (): void => {
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'operational_manager' })
      .then(({ data }) => setUsers(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över VA.'))
      .finally(() => setLoadingUsers(false));
  };

  const fetchResidenceGroups = (params?: { is_active?: boolean; filter_operational_manager?: string }): void => {
    getResidenceGroupsMinimalList(params)
      .then(({ data }) => setResidenceGroups(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart.'))
      .finally(() => setLoadingResidenceGroups(false));
  };

  const fetchRegions = (params?: { is_active?: boolean; filter_operational_manager?: string }): void => {
    getRegionsMinimalList(params)
      .then(({ data }) => setRegions(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regioner.'))
      .finally(() => setLoadingRegions(false));
  };

  const updateSorting = (col: string): void => {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  };

  return (
    <div ref={pageTopRef} className="min-h-full">
      {/* Title */}
      <div className="h-16 p-4 flex gap-x-2 items-center bg-gray-100">
        <NotificationImportantOutlinedIcon />
        <h1 className="text-2xl font-semibold">Katastroffpass</h1>
      </div>
      {/* Filters */}
      <div className="bg-white flex h-13 justify-between">
        <div className="flex">
          {hasPermissions(['admin', 'operational_leader']) && (
            <FilterAutocompleteBox
              options={users ?? []}
              value={users?.find(({ id }) => query.operationalManager === id) ?? null}
              onChange={(_, option) => {
                if (!Array.isArray(option)) {
                  setQuery({ operationalManager: option?.id, page: undefined });
                }
              }}
              placeholder="Sök VA"
              getOptionLabel={(option) => option.name}
              loading={loadingUsers}
              clearable
              className="w-48"
            />
          )}
          <FilterAutocompleteBox
            multiple
            renderTags="simple"
            options={residenceGroups ?? []}
            value={residenceGroups.filter((r) => query.residenceGroups?.split(',').includes(r.id.toString())) ?? null}
            onChange={(_, options) =>
              setQuery({
                residenceGroups: Array.isArray(options)
                  ? options.map((r: OptionsInterface) => r.id).join(',')
                  : undefined,
                page: undefined,
              })
            }
            placeholder="Sök avtalspart"
            getOptionLabel={(option) => option.name}
            loading={loadingResidenceGroups}
            clearable
            className="w-48"
          />
          <FilterAutocompleteBox
            multiple
            renderTags="simple"
            options={regions ?? []}
            value={regions.filter((r) => query.regions?.split(',').includes(r.id.toString())) ?? null}
            onChange={(_, options) =>
              setQuery({
                regions: Array.isArray(options) ? options.map((r: OptionsInterface) => r.id).join(',') : undefined,
                page: undefined,
              })
            }
            placeholder="Sök region"
            getOptionLabel={(option) => option.name}
            loading={loadingRegions}
            clearable
            className="w-48"
          />
          <FilterDatePicker
            views={['year', 'month', 'day']}
            value={query.date ?? dayjs()}
            onChange={(date) => setQuery({ date: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            placeholder="Välj datum"
            className="w-48"
            actions={['clear']}
          />
        </div>
        <div className="flex items-center">
          <span className="pr-2 text-sm text-black/60">Valda team ({totalTeams}) st.</span>
          <CatastropheWorkShiftsFilterPopover
            showDifficultTeams={query.showDifficultTeams}
            setShowDifficultTeams={(val) => setQuery({ showDifficultTeams: val, page: undefined })}
            showWithoutCatastrophe={query.showWithoutCatastrophe}
            setShowWithoutCatastrophe={(val) => setQuery({ showWithoutCatastrophe: val, page: undefined })}
            showInactiveTeams={query.showInactiveTeams}
            setShowInactiveTeams={(val) => setQuery({ showInactiveTeams: val, page: undefined })}
          />
        </div>
      </div>
      {/* Content */}
      <TableBox
        extraHeaders={tableExtraHeaders}
        columns={tableColumns}
        content={teams}
        rowDefinition={(team: TeamCatastropheStatisticsRow) => {
          function beforeNavigate(event: MouseEvent) {
            event.preventDefault();
            event.stopPropagation();
            navigate(`/katastrofpass${location.search}#${team.id}`, { replace: true });
          }
          function fromParam() {
            return 'from=' + encode(`/katastrofpass${location.search}`);
          }
          const openRegion = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/regions/${team.region?.id}`);
          };
          const openTeam = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/teams/${team.id}`);
          };
          const openSchedule = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/schedule/${team.id}?${fromParam()}`);
          };
          const usersQuery = () => encode(JSON.stringify({ ...userFilterDefaults, filter_teams: [team.id] }));
          const openUsers = (event: MouseEvent) => {
            beforeNavigate(event);
            navigate(`/users?query=${usersQuery()}&${fromParam()}`);
          };
          return {
            key: team.id,
            defaultLink: `/teams/${team.id}`,
            cols: [
              {
                content: (
                  <div className="w-full inline-flex justify-between items-center">
                    <span className="mr-2.5">{team.name}</span>
                    {team.difficult && (
                      <Tooltip title="Team med svåra förutsättningar" placement="bottom">
                        <ReportOutlinedIcon fontSize="small" color="action" />
                      </Tooltip>
                    )}
                  </div>
                ),
              },
              team.kp_12,
              team.kp_6,
              team.kp_2,
              team.approved_kp_12,
              team.approved_kp_6,
              team.approved_kp_2,
              team.regional_manager?.name,
              team.operational_manager?.name,
              {
                content: (
                  <MenuTableCell key="extraActions">
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/regions/${team.region.id}`}
                        onClick={openRegion}
                        text="Visa region"
                        label="show_region"
                        icon={<SouthAmericaIcon style={{ marginRight: '10px' }} />}
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    {hasPermissions(['admin', 'operational_leader']) && (
                      <MenuItem className="!p-0">
                        <QuickLinkAction
                          to={`/residences/${team.residence.id}`}
                          text="Visa boende"
                          icon={<BusinessIcon style={{ marginRight: '10px' }} />}
                          label="show_residence"
                          styles={commonStyles.selectionFabStyle3NoPadding}
                        />
                      </MenuItem>
                    )}
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/teams/${team.id}`}
                        onClick={openTeam}
                        text="Visa teamsida"
                        icon={<PrivacyTipIcon style={{ marginRight: '10px' }} />}
                        label="show_team"
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/users?query=${usersQuery()}`}
                        onClick={openUsers}
                        text="Visa anställda"
                        icon={<GroupIcon style={{ marginRight: '10px' }} />}
                        label="show_employees"
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    <MenuItem className="!p-0">
                      <QuickLinkAction
                        to={`/schedule/${team.id}`}
                        onClick={openSchedule}
                        text="Visa schema"
                        icon={<CalendarViewWeekIcon style={{ marginRight: '10px' }} />}
                        label="show_schedule"
                        styles={commonStyles.selectionFabStyle3NoPadding}
                      />
                    </MenuItem>
                    {hasPermissions(['admin']) && team.team_agreement && (
                      <MenuItem className="!p-0">
                        <QuickLinkAction
                          to={`/agreements/${team.team_agreement.agreement.id}`}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            navigate(`/agreements/${team.team_agreement?.agreement.id}`);
                          }}
                          text="Visa avtal"
                          icon={<HowToVoteIcon style={{ marginRight: '10px' }} />}
                          label="show_agreement"
                          styles={commonStyles.selectionFabStyle3NoPadding}
                        />
                      </MenuItem>
                    )}
                  </MenuTableCell>
                ),
                customCell: true,
              },
            ],
          };
        }}
        pagination={{
          totalEntries: totalTeams ?? 0,
          pageSize: PAGE_SIZE,
          page: query.page ?? 1,
          onChange: (_, page) => setQuery({ page }),
        }}
        sorting={{ sortBy: query.sortBy, sortOrder: query.sortOrder, handleSortChange: updateSorting }}
        moreActionsCol
        fullWidth
      />
      {(loading || loadingResidenceGroups || loadingUsers || loadingRegions) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}
    </div>
  );
};

export default CatastropheWorkShifts;
