import React, { useContext, useState, useEffect, ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import { RotateLoader } from 'react-spinners';
import { workShiftsReturnRegex } from '../Common/utilities';

import { AuthContextValue } from '../Common/types';
import { AuthContext } from '../Common/AuthContext';

import { validationSchema, initialValues } from './workShiftSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { Button, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { BackLink } from '../Common/ButtonLinks';
import WorkShiftForm from './components/WorkShiftForm';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { OptionsInterface } from '../Common/types';

import { getUsersMinimalList } from '../User/usersApi';
import { WorkShiftInterface } from './types';
import { MinimalWorkShiftUserInterface } from '../User/types';
import { deleteWorkShift, getWorkShift, updateWorkShift } from './workShiftApi';
import { TeamInterface } from '../Team/types';
import ConfirmationDialog from '../Common/Dialogs/ConfirmationDialog';
import { convertNumToTime } from '../Common/utilities';
import { getTempTeamLeaders } from '../Team/teamApi';
import { getSalaryClassesMinimalList } from '../SalaryClass/salaryClassApi';

const EditWorkShift: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyError, notifySuccess } = useNotify();
  const { id } = useParams() as { id: string };

  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  const [workShift, setWorkShift] = useState(initialValues as WorkShiftInterface);
  const [team, setTeam] = useState({} as TeamInterface);
  const [salaryClassesMinimalList, setSalaryClassesMinimalList] = useState([] as OptionsInterface[]);

  const [participantsMinimalList, setParticipantsMinimalList] = useState([] as MinimalWorkShiftUserInterface[]);

  const [loadingWorkShift, setLoadingWorkShift] = useState(false);
  const [loadingSalaryClasses, setLoadingSalaryClasses] = useState(false);
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  const [undoDialogVisible, setUndoDialogVisible] = useState(false);

  const [approveCatastropheShiftDialogVisible, setApproveCatastropheShiftDialogVisible] = useState(false);
  const [updatingWorkShift, setUpdatingWorkShift] = useState(false);

  // functions
  useEffect(() => {
    fetchWorkShift();
    fetchSalaryClasses();
  }, []);

  useEffect(() => {
    fetchParticipants();
  }, [workShift]);

  const fetchWorkShift = (): void => {
    setLoadingWorkShift(true);
    getWorkShift(parseInt(id))
      .then(({ data }) => {
        setWorkShift(data.data);
        setTeam(data.data.team);
      })
      .catch(() => notifyError('Det gick inte att hämta passet.'))
      .finally(() => setLoadingWorkShift(false));
  };

  const fetchParticipants = (): void => {
    if (workShift.team) {
      setLoadingParticipants(true);
      getUsersMinimalList({
        hide_banned: true,
        filter_teams: [workShift.team.id],
        with_role: true,
        name_with_role: true,
      })
        .then(({ data }) => setParticipantsMinimalList(data.data))
        .catch(() => notifyError('Det gick inte att hämta deltagare.'))
        .finally(() => setLoadingParticipants(false));
    }
  };

  const fetchSalaryClasses = (): void => {
    setLoadingSalaryClasses(true);
    getSalaryClassesMinimalList({ custom: false })
      .then(({ data }) => setSalaryClassesMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta lönearter.'))
      .finally(() => setLoadingSalaryClasses(false));
  };

  // functions
  const handleFormSubmit = (values: WorkShiftInterface, setSubmitting: (isSubmitting: boolean) => void): void => {
    values.scheduled_participants_attributes = values.scheduled_participants;

    setSubmitting(true);

    updateWorkShift(workShift.id, values)
      .then(() => notifySuccess('Passet har uppdaterats.'))
      .then(() => {
        if (location.key !== 'default' && workShiftsReturnRegex.test(location?.state?.pathname)) {
          const { state } = location;
          navigate(state.pathname + state.search + state.hash);
        } else {
          navigate(`/work-shifts`);
        }
      })
      .catch((error: any) => {
        notifyError(`Det gick inte att uppdatera passet: \n${HandleError(error.response.data)}`);
      })
      .finally(() => setSubmitting(false));
  };

  const handleSaveAndReport = (values: WorkShiftInterface, setSubmitting: (isSubmitting: boolean) => void): void => {
    if (workShift.id) {
      values.reported = true;
      values.scheduled_participants_attributes = values.scheduled_participants;

      setSubmitting(true);

      updateWorkShift(workShift.id, values)
        .then(() => notifySuccess('Passet har rapporterats.'))
        .then(() => {
          if (location.key !== 'default' && workShiftsReturnRegex.test(location?.state?.pathname)) {
            const { state } = location;
            navigate(state.pathname + state.search + state.hash);
          } else {
            navigate(`/work-shifts`);
          }
        })
        .catch((error: any) => {
          notifyError(`Det gick inte att uppdatera passet: \n${HandleError(error.response.data)}`);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const undoReportedAt = () => {
    if (workShift.id) {
      updateWorkShift(workShift.id, { reported: false })
        .then(() => notifySuccess('Passet har uppdaterats.'))
        .then(() => navigate(0))
        .catch((error: any) => {
          notifyError(`Det gick inte att uppdatera passet: \n${HandleError(error.response.data)}`);
        });
    }
  };

  const handleDeleteWorkShift = (): void => {
    if (workShift.id) {
      deleteWorkShift(workShift.id)
        .then(() => notifySuccess('Passet har tagits bort.'))
        .then(() => {
          if (location.key !== 'default' && workShiftsReturnRegex.test(location?.state?.pathname)) {
            const { state } = location;
            navigate(state.pathname + state.search + state.hash);
          } else {
            navigate(`/work-shifts`);
          }
        })
        .catch((error: any) => notifyError(`Det gick inte att ta bort passet: \n${HandleError(error.response.data)}`));
    }
  };

  // render
  return (
    <React.Fragment>
      {loadingWorkShift || loadingParticipants || loadingSalaryClasses ? (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader
            loading={loadingWorkShift || loadingParticipants || loadingSalaryClasses || updatingWorkShift}
          />
        </div>
      ) : (
        <React.Fragment>
          <div
            className={css(commonStyles.editViewHeader)}
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <BackLink
                link={
                  location.key === 'default' || !workShiftsReturnRegex.test(location.state?.pathname)
                    ? `/work-shifts`
                    : location.state.pathname + location.state.search + location.state.hash
                }
              />

              <h1 className={css(commonStyles.headerTextStyle)}>
                Redigera pass {dayjs(workShift.date).format('D MMM YYYY')}{' '}
                {workShift.start_time ? convertNumToTime(workShift.start_time) : ''} -{' '}
                {workShift.end_time ? convertNumToTime(workShift.end_time) : ''} {workShift.team?.name}
              </h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {workShift.reported_at && (workShift.locked_at || workShift.invoiced_at) && (
                <>
                  <span
                    style={{
                      marginRight: '14px',
                      letterSpacing: '0.1px',
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#898989',
                    }}
                  >
                    Exporterad
                  </span>

                  <Button
                    style={{
                      maxHeight: '48px',
                      minHeight: '48px',
                      maxWidth: '48px',
                      minWidth: '48px',
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    }}
                    disabled={true}
                  >
                    <LockOutlinedIcon className={css(commonStyles.lockIcon)} />
                  </Button>
                </>
              )}

              {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && workShift.catastrophe_at && (
                <div className="pl-4">
                  {workShift.catastrophe_approved_at ? (
                    <Tooltip title="Rapporterat som godkänt katastroffpass">
                      <Button
                        className="!max-h-[48px] !min-h-[48px] !max-w-[48px] !min-w-[48px] !bg-[#0000000d] !rounded-none"
                        onClick={() => {
                          if (hasPermissions(['admin', 'operational_leader'])) {
                            setApproveCatastropheShiftDialogVisible(true);
                          }
                        }}
                      >
                        <NotificationsOffIcon className={css(commonStyles.lockIcon)} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Rapporterat som katastroffpass">
                      <Button
                        className="!max-h-[48px] !min-h-[48px] !max-w-[48px] !min-w-[48px] !bg-[#0000000d] !rounded-none"
                        onClick={() => {
                          if (hasPermissions(['admin', 'operational_leader'])) {
                            setApproveCatastropheShiftDialogVisible(true);
                          }
                        }}
                      >
                        <NotificationImportantIcon className={css(commonStyles.lockIcon)} />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
              {workShift.reported_at && !workShift.locked_at && !workShift.invoiced_at && (
                <div className="pl-4">
                  <Button
                    style={{
                      maxHeight: '48px',
                      minHeight: '48px',
                      maxWidth: '48px',
                      minWidth: '48px',
                      background: 'rgba(71, 191, 143, 0.12)',
                    }}
                    onClick={() => {
                      setUndoDialogVisible(true);
                    }}
                  >
                    <EditIcon className={css(commonStyles.editIcon)} />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={css(commonStyles.formContainer)}>
            <Formik
              initialValues={workShift}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }): void => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                setSubmitting,
                isValid,
                validateForm,
              }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <WorkShiftForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    setFieldValue={setFieldValue}
                    setSubmitting={setSubmitting}
                    validateForm={validateForm}
                    submitDisabled={isSubmitting || !isValid || updatingWorkShift}
                    workShift={workShift}
                    team={team}
                    salaryClassesMinimalList={salaryClassesMinimalList}
                    participantsMinimalList={participantsMinimalList}
                    // tempTeamLeadersMinimalList={tempTeamLeadersMinimalList}
                    // // substitutesMinimalList={substitutesMinimalList}
                    // // setSubstitutesMinimalList={setSubstitutesMinimalList}
                    removal={handleDeleteWorkShift}
                    handleFormSubmit={handleFormSubmit}
                    handleSaveAndReport={handleSaveAndReport}
                  />
                </form>
              )}
            </Formik>
          </div>

          <ConfirmationDialog
            isVisible={undoDialogVisible}
            onClose={(): void => {
              setUndoDialogVisible(false);
            }}
            onConfirm={(): void => {
              setUndoDialogVisible(false);
              undoReportedAt();
            }}
            title="Vill du ångra inrapporteringen av passet?"
            message={'Endast inrapporterade pass skickas vidare för löneutbetalningar.'}
            confirmButtonText={'JA'}
            closeButtonText={'NEJ'}
          />

          <ConfirmationDialog
            isVisible={approveCatastropheShiftDialogVisible}
            onClose={(): void => {
              setApproveCatastropheShiftDialogVisible(false);
            }}
            onConfirm={(): void => {
              setApproveCatastropheShiftDialogVisible(false);
              setUpdatingWorkShift(true);
              updateWorkShift(workShift.id, { catastrophe_approved: workShift.catastrophe_approved_at ? false : true })
                .then(() => notifySuccess('Passet har uppdaterats.'))
                .then(() => navigate(0))
                .catch((error: any) => {
                  notifyError(`Det gick inte att uppdatera passet: \n${HandleError(error.response.data)}`);
                })
                .finally(() => setUpdatingWorkShift(false));
            }}
            title={
              workShift.catastrophe_approved_at
                ? 'Vill du återställa passet som katastroffpass?'
                : 'Vill du markera passet som godkänt katastroffpass?'
            }
            message={
              <div>
                {workShift.catastrophe_approved_at ? (
                  <p>Passet är manuellt markerat som ett katastroffpass pga att:</p>
                ) : (
                  <p>Passet är automatiskt markerat som ett katastroffpass pga att:</p>
                )}

                <p className="pt-4">
                  Endast{' '}
                  <b>
                    {workShift.scheduled_participants.filter(({ confirmed }) => confirmed).length} av{' '}
                    {workShift.scheduled_participants.length}
                  </b>{' '}
                  deltagare har rapporterats som närvarande.
                </p>
              </div>
            }
            confirmButtonText={'JA'}
            closeButtonText={'NEJ'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditWorkShift;
