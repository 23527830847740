import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
  showRcOnly: boolean | undefined;
  setShowRcOnly: (val: boolean) => void;
  showCatastropheOnly: boolean | undefined;
  setShowCatastropheOnly: (val: boolean) => void;
  scheduled: boolean | undefined;
  setScheduled: (val: boolean) => void;
  notReported: boolean | undefined;
  setNotReported: (val: boolean) => void;
  reported: boolean | undefined;
  setReported: (val: boolean) => void;
  showExported: boolean | undefined;
  setShowExported: (val: boolean) => void;
  banned: boolean | undefined;
  setBanned: (val: boolean) => void;
  showInactiveTeams: boolean | undefined;
  setShowInactiveTeams: (val: boolean) => void;
  showDifficultTeams: boolean | undefined;
  setShowDifficultTeams: (val: boolean) => void;
};

export default function WorkShiftsFilterPopover({
  showRcOnly,
  setShowRcOnly,
  showCatastropheOnly,
  setShowCatastropheOnly,
  scheduled,
  setScheduled,
  notReported,
  setNotReported,
  reported,
  setReported,
  showExported,
  setShowExported,
  banned,
  setBanned,
  showInactiveTeams,
  setShowInactiveTeams,
  showDifficultTeams,
  setShowDifficultTeams,
}: Props) {
  return (
    <BaseFilterPopover>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa bara RC passbesök
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showRcOnly} onChange={(_, checked) => setShowRcOnly(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa bara katastrofpass
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showCatastropheOnly} onChange={(_, checked) => setShowCatastropheOnly(checked)} />}
        />
      </div>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <b>Filtrera på status:</b>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa schemalagda
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={scheduled} onChange={(_, checked) => setScheduled(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa &quot;ej rapporterade&quot;
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={notReported ?? true} onChange={(_, checked) => setNotReported(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa rapporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={reported ?? true} onChange={(_, checked) => setReported(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa exporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showExported ?? true} onChange={(_, checked) => setShowExported(checked)} />}
        />
      </div>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa avslutade anställda
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={banned ?? false} onChange={(_, checked) => setBanned(checked)} />}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa inaktiva team
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={
            <Switch checked={showInactiveTeams ?? false} onChange={(_, checked) => setShowInactiveTeams(checked)} />
          }
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa bara svåra team
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={
            <Switch checked={showDifficultTeams ?? false} onChange={(_, checked) => setShowDifficultTeams(checked)} />
          }
        />
      </div>
    </BaseFilterPopover>
  );
}
