import { AxiosResponse } from 'axios';

import { WorkShiftRow, WorkShiftInterface } from './types';
import API from '../Common/axios';
import { ListParams } from '../Common/types';

type GetWorkShiftsParams = {
  filter_user?: string;
  filter_teams?: (number | undefined)[];
  filter_regions?: (number | undefined)[];
  filter_residence_clusters?: (number | undefined)[];
  filter_month_year?: string;
  show_rc_only?: boolean;
  show_catastrophe_only?: boolean;
  show_scheduled?: boolean;
  show_not_reported?: boolean;
  show_reported?: boolean;
  show_exported?: boolean;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
  page?: number;
};

export async function getWorkShifts(params: GetWorkShiftsParams = {}) {
  return API.get<{
    data: WorkShiftRow[];
    meta: {
      count: number;
      hours: number | null;
      user_name: string | null;
      d_hours: number | null;
      tl_hours: number | null;
      rc_hours: number | null;
    };
  }>('v1/work_shifts', { params });
}

export const getWorkShift = (id: number): Promise<AxiosResponse> => {
  return API.get(`v1/work_shifts/${id}`);
};

export const createWorkShift = (work_shift: WorkShiftInterface): Promise<AxiosResponse> => {
  return API.post('v1/work_shifts', { work_shift });
};

export const updateWorkShift = (
  id: number | undefined,
  work_shift: WorkShiftInterface | { reported: boolean } | { catastrophe_approved: boolean }
): Promise<AxiosResponse> => {
  return API.patch(`v1/work_shifts/${id}`, { work_shift });
};

export const deleteWorkShift = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/work_shifts/${id}`);
};

export const getLatestWorkShift = (params: { team_id: number }): Promise<AxiosResponse> => {
  return API.get('v1/work_shifts/latest', { params });
};

export const getTempTeamLeaders = (params: {
  team_id: string | number;
  with_role?: boolean;
  with_team?: boolean;
}): Promise<AxiosResponse> => {
  return API.get('v1/work_shifts/temp_team_leaders', { params });
};

export const getSubstitutes = (params: {
  team_id: string | number;
  work_shift_id?: string | number;
  date?: string;
  with_role?: boolean;
}): Promise<AxiosResponse> => {
  return API.get('v1/work_shifts/substitutes', { params });
};

export const syncExportedStatus = (): Promise<AxiosResponse> => {
  return API.get('v1/work_shifts/sync_exported_status', {});
};
