import { AxiosResponse } from 'axios';
import API from '../Common/axios';
import { TeamCatastropheStatisticsRow } from './types';

export const getTeamCatastropheStatistics = (params: {
  filter_operational_manager?: string;
  filter_residence_groups?: (number | undefined)[];
  filter_regions?: (number | undefined)[];
  date?: string;
  is_active?: boolean;
  show_difficult?: boolean;
  show_without_catastrophe?: boolean;
  show_inactive?: boolean;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
  page?: number;
}): Promise<AxiosResponse> => {
  return API.get<{ data: TeamCatastropheStatisticsRow[] }>('/v1/statistics/team_catastrophe_work_shifts', {
    params,
  });
};
